<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- <b-tab
      @click="changeOptions('general')"
    >
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options === 'general'"
        :general-data="options === 'general'"
      />
    </b-tab> -->

    <b-tab
      active
      @click="changeOptions('password')"
    >
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password
        v-if="options === 'password'"
        :general-data="options === 'password'"
      />
    </b-tab>

  </b-tabs>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingNotification,
  },
  data() {
    return {
      options: 'password',
    }
  },
  methods: {
    changeOptions(val) {
      this.options = val
    },
  },
}
</script>
